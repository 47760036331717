<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search disableExport>
      <va-data-table :fields="fields" disableShow disableClone show-expand  items-per-page="300">
           <template v-slot:[`field.pagado`]="{ item }">
        <fixy-field-boolean
        :value="item.pagado"
        ></fixy-field-boolean>
        </template>
        <template v-slot:[`field.totales`]="{ item }">
          <v-chip color="blue" dark v-if="item.pagado == '1' || item.pagado == true">
            $ {{ item.total }}
          </v-chip>
          <v-chip color="red" dark v-if="item.pagado == '0' || item.pagado == false">
            $ {{ item.total_act }}
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ item }">
          <v-data-table
            :headers="headers"
            :items="item.detalle"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.precio="{ item }">
              <v-chip color="blue" dark v-if="item.pagado == '1' || item.pagado == true">
                $ {{ item.precio }}
              </v-chip>
              <v-chip color="red" dark v-if="item.pagado == '0' || item.pagado == false">
                $ {{ item.producto.precio }}
              </v-chip>
            </template>
            <template v-slot:item.total="{ item }">
              <v-chip color="blue" dark v-if="item.pagado == '1' || item.pagado == true">
                $ {{ item.total }}
              </v-chip>
              <v-chip color="red" dark v-if="item.pagado == '0' || item.pagado == false">
                $ {{ item.total_act }}
              </v-chip>
            </template>
          </v-data-table>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      registros: null,
      headers: [
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "producto.nombre",
        },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio U.", value: "precio" },
        { text: "Total", value: "total" },
      ],
      filters: [
        { source: "tipopago", type: "select" },
        {
          source: "cliente_id",
          label: "Cliente",
          type: "autocomplete",
          model: "cliente_id",
          sortable: false,
          attributes: {
            reference: "clientes",
            "item-text": "nombre",
          },
        },
        {
          source: "antes_de",
          type: "date",
          attributes: { format: "short" },
        },
        {
          source: "despues_de",
          type: "date",
          attributes: { format: "short" },
        },

        { source: "pagado", type: "boolean" },
      ],
      fields: [
        {
          source: "fecha",
          type: "date",
          sortable: true,
          attributes: { format: "short" },
        },
        "nroventa",
        {
          source: "cliente",
          label: "Nombre",
          type: "reference",
          attributes: {
            reference: "clientes",
            "item-text": "nombre",
          },
        },
        { source: "tipopago", type: "select", sortable: true },
        { source: "pagado", type: "boolean" },
        { source: "totales" },
      ],
    };
  },
};
</script>
