<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="dateFormatted"
          v-bind="commonProps"
          readonly
          append-icon="mdi-calendar"
          v-on="on"
          :filled="filled"
          @click:clear="input = null"
        ></v-text-field>
      </template>
      <v-date-picker
        :value="getDate"
        @change="change"
        @input="updateDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Editable from "vuetify-admin/src/mixins/editable";
/**
 * Use for date type value editing. Is composed of a readonly textfield associated to a vuetify datepicker.
 * Do not support time, use classic VaTextInput in that case.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Date on ISO format to be edited.
     * @model
     */
    value: {
      type: String,
      default() {
        return this.$d(new Date(), this.format);
      },
    },
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: true,
    },
    /**
     * Name of date format to use for textfield input.
     * Must be predefined on your VueI18n plugin.
     */
    format: {
      type: String,
      default() {
        return this.$admin.options.dateFormat;
      },
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    dateFormatted() {
      return this.input
        ? this.$d(this.sumarDias(this.input, 1), this.format)
        : this.$d(this.sumarDias(new Date(), 0), this.format);
    },
    getDate() {
      /**
       * Return ISO 8601
       */
      let date = this.input ? this.sumarDias(this.input, 1) : new Date();
      let month = 1 + date.getMonth();
      if (month < 10) {
        month = `0${month}`;
      }
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      return `${date.getFullYear()}-${month}-${day}`;
    },



  },

  methods: {
         getsDate() {
      /**
       * Return ISO 8601
       */
      let date = this.input ? this.sumarDias(this.input, 0) : new Date();
      let month = 1 + date.getMonth();
      if (month < 10) {
        month = `0${month}`;
      }
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      return `${date.getFullYear()}-${month}-${day}`;
    },
    sumarDias(fecha, dias) {
      fecha = new Date(fecha);
      fecha.setDate(fecha.getDate() + dias);
      return fecha;
    },
    updateDate(val) {
      this.menu = false;
      this.update(val);
      console.log("dias : " + val);
    },
  },
  mounted() {
 
this.updateDate(this.getsDate());
    
    
  },
};
</script>