<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" :include="['categoria', 'media']">
      <va-data-table :fields="fields">
         <template v-slot:[`field.images`]="{ item }">
          <v-avatar
             tile
             size="56"
          >
        
              <v-img
               :src="item.images ? item.images.thumbnails.Productsthumb : '/imagenes/no-product-image.png'"
                :alt="item.nombre"
                :title="item.nombre"
             
              ></v-img>
        
          </v-avatar>
         
        </template>
         </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "nombre",
        {
          source: "categoria",
          type: "select",
          attributes: {
            reference: "categorias",
            "item-text": "nombre",
          },
        },
      ],
      fields: [
        {
          source:"images",
          label: "Imagen",
        },
        { source: "codigo", type: "number" },
        "nombre",
        "descripcion",
        {
          source: "precio",
          type: "number",
          attributes: { format: "currency" },
        },
        { source: "estado", type: "boolean" },
        {
          source: "categoria",
          type: "reference",
          sortable: true,
          attributes: {
            reference: "categorias",
            "item-text": "nombre",
          },
        },
      ],
    };
  },
};
</script>
