export default [
    { name: "users", icon: "mdi-account", routes: ["list"] },
    {
        name: "categorias",
        icon: "mdi-file-tree",
        label: "name",
        translatable: true,
        actions: undefined,
    },
    { name: "productos", icon: "mdi-food-fork-drink", label: "name" },
    {
        name: "clientes",
        icon: "mdi-account-multiple",
        label: "name",
        actions: undefined,
    },
    {
        name: "padrones",
        icon: "mdi-account-check",
        label: "name",
        actions: undefined,
    },
    {
        name: "reportesmutual",
        icon: "mdi-cart-outline",
        label: "name",
        actions: undefined,
    },
    {
        name: "ventas",
        icon: "mdi-silverware-variant",
        label: "name",
        actions: undefined,
    },

];
