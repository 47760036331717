/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
    {
        icon: "mdi-view-dashboard",
        text: i18n.t("menu.dashboard"),
        link: "/",
    },
    { divider: true },
    {
        text: "Menu",
        icon: "mdi-food",
        children: [
            admin.getResourceLink("productos"),
            admin.getResourceLink("categorias"),
        ]
    },
    { divider: true },

    admin.getResourceLink("clientes"),
    { divider: true },
    admin.getResourceLink("ventas"),
    { divider: true },
    admin.getResourceLink("detalleventa"),
   
    {
        text: "Mutual 378",
        icon: "mdi-bus-side",
        children: [
            {
                text: "Afiliados Autorizados",
                icon: "mdi-account-check",
                link: "/padrones"
            },
            {
                text: "Consumos",
                icon: "mdi-cart-outline",
                link: "/reportesmutual"
            }
        ]
    },
    { divider: true },
    admin.getResourceLink("users"),
];
