<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <va-select-input
                            source="categoria"
                            reference="categorias"
                            item-text="nombre"
                            required
                            model="categoria_id"
                        ></va-select-input>
                        <va-text-input source="nombre" required></va-text-input>
                        <va-boolean-input source="estado"></va-boolean-input>
                        <va-number-input
                            source="codigo"
                        ></va-number-input>
                        <va-number-input
                            source="precio"
                            format="currency"
                            required
                        ></va-number-input>
                        <va-file-input
                            source="images"
                            preview
                            accept="image/*"
                        ></va-file-input>
                        <va-text-input
                            source="descripcion"
                            multiline
                            required
                        ></va-text-input>
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
export default {
    props: ["id", "title", "item"],
};
</script>
