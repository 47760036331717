import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";

import VentaProductos from "./components/fields/VentaProductos";
import autocompleteInput from "./components/inputs/AutocompleteInput";
import BooleanInputSwitch from "./components/inputs/BooleanInputSwitch";
import DateInputCalendar from "./components/inputs/DateInputCalendar";

import FieldBoolean from "./components/fields/BooleanField";

Vue.config.productionTip = false;

Vue.component("fixy-venta-productos", VentaProductos);
Vue.component("fixy-autocompleteinput", autocompleteInput);
Vue.component("fixy-booleaninput-switch", BooleanInputSwitch);
Vue.component("fixy-dateinput-calendar", DateInputCalendar);

Vue.component("fixy-field-boolean", FieldBoolean);

/**
 * Date format
 */
["es"].forEach((locale) => {
    i18n.setDateTimeFormat(locale, {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
        },
    });
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    admin,
    render: (h) => h(App),
}).$mount("#app");
