<template>
    <va-edit-layout>
        <categorias-form :id="id" :title="title" :item="item"></categorias-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"],
};
</script>
