<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters">
            <va-data-table :fields="fields"> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [],
            fields: [
                {
                    source: "producto",
                    type: "reference",
                    attributes: {
                        reference: "productos",
                        "item-text": "nombre",
                    },
                },
                { source: "cantidad", type: "number" },
                {
                    source: "precio",
                    type: "reference",
                    attributes: {
                        reference: "productos",
                        "item-text": "precio",
                    },
                },
                {
                    source: "total",
                    type: "number",
                    attributes: { format: "currency" },
                },
            ],
        };
    },
};
</script>
