var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{attrs:{"filters":_vm.filters,"disable-global-search":"","disableExport":""}},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disableShow":"","disableClone":"","show-expand":"","items-per-page":"300"},scopedSlots:_vm._u([{key:"field.pagado",fn:function(ref){
var item = ref.item;
return [_c('fixy-field-boolean',{attrs:{"value":item.pagado}})]}},{key:"field.totales",fn:function(ref){
var item = ref.item;
return [(item.pagado == '1' || item.pagado == true)?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(" $ "+_vm._s(item.total)+" ")]):_vm._e(),(item.pagado == '0' || item.pagado == false)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" $ "+_vm._s(item.total_act)+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":item.detalle,"items-per-page":5},scopedSlots:_vm._u([{key:"item.precio",fn:function(ref){
var item = ref.item;
return [(item.pagado == '1' || item.pagado == true)?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(" $ "+_vm._s(item.precio)+" ")]):_vm._e(),(item.pagado == '0' || item.pagado == false)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" $ "+_vm._s(item.producto.precio)+" ")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.pagado == '1' || item.pagado == true)?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(" $ "+_vm._s(item.total)+" ")]):_vm._e(),(item.pagado == '0' || item.pagado == false)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" $ "+_vm._s(item.total_act)+" ")]):_vm._e()]}}],null,true)})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }