<template>
    <va-show-layout>
        <va-show :item="item">
            <v-row justify="center">
                <v-col sm="6">
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                {{ title }}
                            </div>
                        </template>
                        <v-card-text>
                            <va-field
                                source="fecha"
                                type="date"
                                format="short"
                            ></va-field>
                            <va-field source="nroventa"></va-field>
                            <va-field
                                source="cliente"
                                type="reference"
                                reference="clientes"
                                item-text="nombre"
                            ></va-field>
                            <va-field
                                source="tipopago"
                                type="select"
                            ></va-field>
                            <va-field
                                source="productos"
                                type="reference"
                                reference="productos"
                                item-text="nombre"
                            ></va-field>
                            <va-field
                                source="detalleventa"
                                type="reference"
                                reference="detalleventa"
                                item-text="nombre"
                            ></va-field>
                            <va-field
                                source="total"
                                type="number"
                                format="currency"
                            ></va-field>
                            <va-field
                                source="created_at"
                                type="date"
                            ></va-field>
                            <va-field
                                source="updated_at"
                                type="date"
                            ></va-field>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </va-show>
    </va-show-layout>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
