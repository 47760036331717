<template>
  <div>
    <va-form :id="id" :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <fixy-dateinput-calendar
                source="fecha"
                format="short"
                model="fecha"
                v-model="fechaActual"
                required
                @change="dateSeleccionado()"
                locale="es"
              ></fixy-dateinput-calendar>

              <va-autocomplete-input
                reference="clientes"
                v-model="clienteid"
                label="Cliente"
                item-text="nombre"
                item-value="id"
                :minChars="4"
                searchQuery="v"
                resource="clientes"
                placeholder="Escriba legajo o apellido"
                @change="autorizadoMutual()"
                textoColumna="nombre"
                source="cliente_id"
                required
              >
              </va-autocomplete-input>

              <va-select-input
                source="tipopago"
                v-model="tpago"
                @change="modificoPago()"
                required
              >
              </va-select-input>

              <fixy-booleaninput-switch v-model="pagado" source="pagado">
              </fixy-booleaninput-switch>

              <va-autocomplete-input
                label="Producto"
                placeholder="Ingrese codigo o nombre"
                reference="productos"
                item-text="nombre"
                textoColumna="nombre"
                item-value="id"
                :minChars="3"
                v-model="productoSeleccionado"
                returnobject
                :required="id != null ? false : true"
              >                   
              </va-autocomplete-input>

              <va-number-input
                source="cantidad"
                v-model="cantidad"
                required
              ></va-number-input>
              <v-btn small color="info" @click="agregarProductos(), cantidad = 1">
                Agregar</v-btn
              >
              <va-table-input
                label=" "
                source="detalleventa"
                reference="detalleventa"
                :value="detalle"
                v-model="detalle"
              >
              </va-table-input>
            </v-card-text>

            <va-save-button></va-save-button>
          </base-material-card>
        </v-col>
      </v-row>
    </va-form>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="white--text">
          Espere por favor...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Field from "vuetify-admin/src/mixins/field";
import Swal from "sweetalert2/dist/sweetalert2.all.js";

export default {
  mixins: [Field],
  props: ["id", "title", "item", "reference"],
  data() {
    return {
      productos: [{ nombre: "prueba", id: 1 }],
      clienteid: 1,
      dialog: false,
      mesSelect: null,
      diaSelect: null,
      mesBuscar: null,
      anioSelect: null,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      fechaActual: null,
      precio: 0,
      productoSeleccionado: [],
      cantidad: 1,
      total: 0,
      totalventa: {
        source: "totalVenta",
        valor: 0,
      },
      detalle: [],
      pagado: false,
      tpago: "Efectivo",
      cliente: null,
      cliente_id: null,
    };
  },

  methods: {
    modificoPago() {
      if (this.tpago == "Efectivo") {
        this.pagado = true;
      } else if (this.tpago == "Mutual") {
        this.pagado = false;
        this.autorizadoMutual();
      }
    },
    async datosDelCliente() {
      this.dialog = true;
      let { data } = await this.$store.dispatch("clientes/getOne", {
        id: this.clienteid,
      });

      this.cliente = data;
      console.log("cliente: " + this.cliente);
      this.clienteAutorizado(this.cliente.dni);
    },
    async clienteAutorizado(dni) {
      let { data } = await this.$store.dispatch("padrones/getList", {
        filter: {
          dni: dni,
          mes: this.mesBuscar,
          anio: this.anioSelect,
        },
      });

      this.dialog = false;

      if (data.length < 1) {
        var mesAnterior;
        if (this.mesSelect == 0) {
          mesAnterior = this.meses[11];
        } else {
          mesAnterior = this.meses[this.mesSelect - 1];
        }

        let { data } = await this.$store.dispatch("padrones/getList", {
          filter: {
            dni: dni,
            mes: mesAnterior,
            anio: this.anioSelect,
          },
        });

        if (data.length < 1) {
          Swal.fire({
            icon: "error",
            title: "Sin Autorizacion",
            html:
              "MES: " +
              this.mesBuscar +
              " / " +
              mesAnterior +
              "<br> CLIENTE: " +
              this.cliente.nombre +
              ", " +
              this.cliente.apellido +
              "",
          });
        }
      }
    },
    agregarProductos() {
      this.total = this.productoSeleccionado.precio * this.cantidad;

      this.detalle.push({
        productoid: this.productoSeleccionado.id,
        nombre: this.productoSeleccionado.nombre,
        cantidad: this.cantidad,
        precio: this.productoSeleccionado.precio,
        total: this.total,
      }),
        (this.totalventa.valor += this.total);

      console.log("Producto Seleccionado: " + this.productoSeleccionado);
    },
    detalleEdit() {
      for (let index = 0; index < this.item.detalle.length; index++) {
        this.detalle.push({
          productoid: this.item.detalle[index].producto_id,
          nombre: this.item.detalle[index].producto.nombre,
          cantidad: this.item.detalle[index].cantidad,
          precio: this.item.detalle[index].precio,
          total:
            this.item.detalle[index].precio * this.item.detalle[index].cantidad,
        });
        //(this.totalventa.valor += this.total);
      }
    },
    autorizadoMutual() {
      if (this.clienteid != null && this.tpago == "Mutual" && !this.item) {
        this.datosDelCliente();
      }
    },

    dateSeleccionado() {
      var seleccion;
      if (this.fechaActual) {
        seleccion = new Date(this.fechaActual);
      } else {
        seleccion = new Date();
      }

      this.anioSelect = seleccion.getFullYear();
      this.mesSelect = seleccion.getMonth();
      this.diaSelect = seleccion.getDate();

      this.mesBuscar = this.meses[this.mesSelect];

      console.log("Año: " + this.anioSelect);
      console.info("Mes: " + this.meses[this.mesSelect]);
      console.info("Dia: " + this.diaSelect);

      this.autorizadoMutual();
    },
  },

  watch: {
    cliente_id(val) {
      //console log value
      console.log("cliente_id: " + val);
    },
    clienteid(val) {
      console.log('clienteid: ' + val);
      this.cliente_id=val;
    },
    detalle() {
      console.log(this.detalle);
    },
  },

  async mounted() {
    console.log(this.detalle);
    this.dateSeleccionado();
    await this.item;
    if (this.id) {
      this.detalleEdit();
      this.clienteid = this.item.cliente_id;

      console.log("Cliente ID: " + this.item.cliente_id);
    }
  },
};
</script>
