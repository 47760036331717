<template>
  <v-switch
    :false-value="false"
    :true-value="true"
    :input-value="input"
    v-bind="commonProps"
    @change="changeState"
    inset
  ></v-switch>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
export default {
  name: "BooleanInputSwitch",
  mixins: [Input],
  props:
    /**
     * Boolean to be edited.
     * @model
     */
    ["value"],
  methods: {
    changeState(value) {
      this.change(value);
      this.update(value);
      console.log("Boolean: " + value);
    },
  },
  watch: {
    value() {
      this.changeState(this.value ? true : false);
    },
  },
};
</script>
