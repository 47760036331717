<template>
    <va-create-layout>
        <categorias-form :title="title" :item="item"></categorias-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
