<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" lg="6">
        <base-material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="primary"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">Ventas anuales</h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Ventas por mes
          </p>

          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-calendar </v-icon>
            <span class="caption grey--text font-weight-light"
              >Ventas por mes</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="primary"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info"> mdi-refresh </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Cantidad de ventas
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            L. Blanca: Semana actual | L. Roja: Semana pasada
          </p>

          <template v-slot:actions>
            <v-icon class="mr-1" small> mdi-calendar </v-icon>
            <span class="caption grey--text font-weight-light"
              >Ventas por semana</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="warning"
          icon="mdi-currency-usd-off"
          title="Ventas Impagas"
          :value="ventasImpagas"
          sub-icon="mdi-calendar"
          :sub-text="'Periodo: ' + mes + ' de ' + anio"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="success"
          icon="mdi-currency-usd"
          title="Ventas en efectivo"
          :value="ventasEfectivo"
          sub-icon="mdi-calendar"
          :sub-text="mes + ' de ' + anio"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="info"
          icon="mdi-store"
          title="Total hoy"
          :value="ventasHoy"
          sub-icon="mdi-calendar"
          sub-text="Dia de hoy"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6">
        <base-material-stats-card
          color="orange"
          icon="mdi-food-fork-drink"
          title="Mas vendido"
          :value="productoMasVendido"
          sub-icon="mdi-information-variant"
          sub-icon-color="blue"
          sub-text="Historico"
        />
      </v-col>

      <v-col cols="12" md="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Pedidos de hoy</div>

            <div class="subtitle-1 font-weight-light">
              Puede visualizar desde aqui las ultimas ventas. (Futuro modulo
              comandero)
            </div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="items" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emailsSubscriptionChart: {
        data: {
          labels: ["E", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
          series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
        },
        options: {
          axisX: {
            showGrid: true,
          },
           axisY: {
            labelInterpolationFnc: function (value) {
              return value / 1000 + "k";
            },
          },
          low: 0,
          high: 0,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      dailySalesChart: {
        data: {
          labels: ["D", "L", "M", "M", "J", "V", "S"],
          series: [
            [0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0],
          ],
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 0, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      ventasImpagas: "$ 0,00",
      ventasEfectivo: "$ 0,00",
      ventasHoy: "$ 0,00",
      mes: "Cargando...",
      anio: "Cargando...",
      productoMasVendido: "Cargando...",
      headers: [
        {
          sortable: false,
          text: "Cliente",
          value: "cliente",
        },
        {
          sortable: false,
          text: "Cantidad",
          value: "cantidad",
        },
        {
          sortable: false,
          text: "Producto",
          value: "producto",
          align: "left",
        },
      ],
      items: [],
      tabs: 0,
    };
  },

  methods: {
    async metricas() {
      await this.$store
        .dispatch("reportesmutual/getList", {
          include: {
            metricas: true,
          },
        })
        .then((response) => {
          console.log(response);
          this.ventasImpagas =
            "$ " +
            (response.data.impagas == null ? "0,00" : response.data.impagas);
          this.ventasEfectivo =
            "$ " +
            (response.data.ventasefectivo == null
              ? "0,00"
              : response.data.ventasefectivo);
          this.ventasHoy = "$ " + response.data.ventasdehoy;
          this.mes = response.data.mes;
          this.anio = response.data.anio;
          this.productoMasVendido = response.data.masvendido;
          this.items = response.data.comandero;
          this.dailySalesChart.data.series = response.data.serieSemana.series;
          var porcentaje = (response.data.serieSemana.maximo * 10) / 100;
          this.dailySalesChart.options.high =
            response.data.serieSemana.maximo + porcentaje;
          (this.emailsSubscriptionChart.data.series =
            response.data.ventasPorMes.series),
            (this.emailsSubscriptionChart.options.high = Math.round(
              response.data.ventasPorMes.maximo +
                (response.data.ventasPorMes.maximo * 10) / 100
            ));
  
        });
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },
  },
  mounted() {
    this.metricas();
  },
};
</script>
