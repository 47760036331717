import i18n from "@/i18n";

/**
 * Date format
 */

/**
 * Number format
 */
i18n.setDateTimeFormat("es", {
    short: {
        year: "numeric",
        month: "short",
        day: "numeric",
    },
    long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
    },
});

i18n.setNumberFormat("es", {
    currency: {
        style: "currency",
        currency: "ARG",
    },
});
