<template>
    <va-create-layout>
        <reportesmutual-form :title="title" :item="item"></reportesmutual-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
