<template>
  <div>
    <base-material-card title="Ventas del periodo" v-show="!showForm">
      <v-data-table
        :headers="headers"
        :items.sync="response.data"
        class="elevation-1"
        show-expand
      >
        <template v-slot:[`item.total`]="{ item }">
          <v-chip
            :color="
              sumarVentas(item.ventas_impagas_mutual) >= 15000 ? 'red' : 'blue'
            "
            dark
          >
            $ {{ sumarVentas(item.ventas_impagas_mutual) }}
          </v-chip>
        </template>

        <template v-slot:expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <div class="row">
              <div class="col-sm-3">Fecha</div>
              <div class="col-sm-3">Importe</div>
              <div class="col-sm-3">Importe Actualizado</div>
              <div class="col-sm-2">Detalle</div>
              <div class="col-sm-1">Excluir</div>
            </div>
            <div
              class="row"
              v-for="venta in item.ventas_impagas_mutual"
              :key="venta.id"
            >
              <div class="col-sm-3">
                {{ dias[new Date(venta.fecha).getDay()] }},
                {{ new Date(venta.fecha).getDate() }} -
                {{ meses[new Date(venta.fecha).getMonth()] }} -
                {{ new Date(venta.fecha).getFullYear() }}
              </div>

              <div class="col-sm-3">$ {{ importe(venta.detalle) }}</div>
              <div class="col-sm-3">
                $ {{ importeActualizado(venta.detalle) }}
              </div>
              <div class="col-sm-2">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="green"
                  :href="'http://localhost:8080/ventas/' + venta.id + '/edit'"
                  target="_blank"
                >
                  <v-icon dark> mdi-eye </v-icon>
                </v-btn>
              </div>
              <div class="col-sm-1">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="red"
                  @click.prevent="deleteVenta(venta.id)"
                >
                  <v-icon dark> mdi-minus </v-icon>
                </v-btn>
              </div>
            </div>
          </td>
        </template>
      </v-data-table>

      <v-btn
        :loading="loading"
        :disabled="loading"
        color="green"
        class="ma-2 white--text"
        @click="generarReporte()"
        redirect
      >
        Crear reporte
        <v-icon right dark> mdi-newspaper </v-icon>
      </v-btn>
    </base-material-card>

    <va-form :id="id" v-show="showForm">
      <v-row justify="center">
        <v-col sm="6">
          <base-material-card>
            <template v-slot:heading>
              <div class="display-2">
                {{ title }}
              </div>
            </template>
            <v-card-text>
              <v-alert border="top" color="red lighten-2" dark>
                Buscar los clientes autorizados del periodo:
              </v-alert>

              <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                v-show="mensajeError"
              >
                {{ mensajeError }}
              </v-alert>
              <va-select-input
                source="mes"
                v-model="mes"
                required
              ></va-select-input>
              <va-select-input
                source="anio"
                v-model="anio"
                required
              ></va-select-input>
            </v-card-text>
            <va-action-button
              label="Buscar"
              icon="mdi-search-web"
              @click="buscarVentas()"
            ></va-action-button>
          </base-material-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text class="white--text">
            Espere por favor...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </va-form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.all.js";
export default {
  props: ["id", "title"],
  data() {
    return {
      ventasExport: [],
      exportar: false,
      loading: false,
      exede: false,
      excluir: [0],
      dialog: false,
      mes: null,
      anio: null,
      mensajeError: null,
      showForm: true,
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      dias: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
      ],
      response: [
        {
          data: null,
        },
      ],
      headers: [
        {
          text: "Apellido y nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Legajo", value: "legajo", align: "center" },
        { text: "Consumo del periodo", value: "total", align: "end" },
      ],
      headersdetalle: [
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Cantidad", value: "legajo", align: "center" },
        { text: "Importe actualizado", value: "", align: "end" },
      ],
    };
  },
  methods: {
    async generarReporte() {
      this.loading = true;

      if (this.exede == true) {
        Swal.fire(
          "Limite Excedente",
          "Verifique las ventas, un cliente excede el limite ($15000).",
          "error"
        ).then(() => {
          this.loading = false;
        });
      } else {
        this.loading = false;

        await this.$store
          .dispatch("reportesmutual/getList", {
            filter: {
              mes: this.mes,
              anio: this.anio,
            },
            include: {
              excluir: this.excluir,
              exportar: true,
              lista:this.ventasExport,
            },
          })
          .then((response) => {
            this.response = response;
            this.verificarExcedente(response.data);

            if(response.data === true){
              window.location.href = '/reportesmutual';
            }
          });
      }

      console.log("excede: " + this.exede);
    },

    deleteVenta: function (ventaid) {
      this.excluir.push(ventaid); //añadimos  la variable  al array

      console.log("Excluir:" + this.excluir);
      this.buscarVentas();
    },
    listaVentasExport(ventaid) {
      this.ventasExport.push(ventaid); //añadimos el la variable persona al array
    },
    verificarExcedente(items) {
      this.ventasExport = [];
      for (let index = 0; index < items.length; index++) {
        var importe = 0;
        // RECORRO CLIENTES
        for (let iv = 0; iv < items[index].ventas_impagas_mutual.length; iv++) {
          // RECORRO LAS VENTAS
          console.log('ventaid: ' + items[index].ventas_impagas_mutual[iv].id)
          console.log('array: ' + this.ventasExport)
          this.listaVentasExport(items[index].ventas_impagas_mutual[iv].id);
          for (
            let i = 0;
            i < items[index].ventas_impagas_mutual[iv].detalle.length;
            i++
          ) {
            // RECORRO LOS DETALLES DE VENTA
            importe +=
              items[index].ventas_impagas_mutual[iv].detalle[i].producto[
                "precio"
              ] * items[index].ventas_impagas_mutual[iv].detalle[i].cantidad;
          }
        }

         
        

      }

     
    },
    sumarVentas(items) {
      var importe = 0;
      for (let index = 0; index < items.length; index++) {
        // RECORRO VENTAS

        for (let i = 0; i < items[index].detalle.length; i++) {
          // RECORRO LOS DETALLES DE VENTA
          importe +=
            items[index].detalle[i].producto["precio"] *
            items[index].detalle[i].cantidad;
        }
      }

      return importe;
    },
    importe(items) {
      var importe = 0;
      for (let index = 0; index < items.length; index++) {
        importe += items[index].precio * items[index].cantidad;
      }
      return importe;
    },
    importeActualizado(items) {
      var importe = 0;
      for (let index = 0; index < items.length; index++) {
        //RECORRO LOS DETALLE DE VENTA
        importe += items[index].producto.precio * items[index].cantidad;
      }

      return importe;
    },
    async buscarVentas() {
      if (this.mes && this.anio) {
        this.mensajeError = null;
        this.dialog = true;
        this.exede = false;
        console.log("nuevo excede: " + this.exede);

        await this.$store
          .dispatch("reportesmutual/getList", {
            filter: {
              mes: this.mes,
              anio: this.anio,
            },
            include: {
              excluir: this.excluir,
              ventas:true
            },
          })
          .then((response) => {
            this.response = response;
            this.verificarExcedente(response.data);
          });

        if (this.response.data.length == 0) {
          this.mensajeError =
            "No puede crear un reporte en el periodo seleccionado: "+
            "Motivos probables: "+
            "(Sin clientes autorizados) | (Sin ventas realizadas) | (Ya existe un reporte emitido).";
        } else {
          this.showForm = false;
        }

        console.log(this.response);
        this.dialog = false;
      } else {
        this.mensajeError = "Selecciones el mes y año antes de buscar.";
      }
    },
  },
};
</script>
