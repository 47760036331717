<template>
  <v-autocomplete
    v-bind="commonProps"
    :filled="filled"
    :loading="loading"
    :item-text="getItemText"
    :item-value="getItemValue"
    :items="items || choices"
    :search-input.sync="search"
    :minChars="minChars"
    :searchQuery="searchQuery"
    :filter="textoColumna ? customFilter : null"
    @change="change"
    :return-object="returnobject"
    v-model="seleccion"
  >
  </v-autocomplete>
</template>

<script>
import Input from "vuetify-admin/src/mixins/input";
import Multiple from "vuetify-admin/src/mixins/multiple";
import ReferenceInput from "vuetify-admin/src/mixins/reference-input";

/**
 * Value editing from a searchable choices. Support multiple and references.
 * Allows searching of linked resources from your API.
 */
export default {
  mixins: [Input, Multiple, ReferenceInput],

  props: {
    minChars: {
      type: Number,
      default: 3,
    },
    searchQuery: {
      type: String,
      default: "q",
    },
    textoColumna: {
      type: String,
      default: "nombre",
    },
    filtroColumnas: {
      type: Boolean,
      default: false,
    },
    returnobject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, Number, Array],
    },
    /**
     * Enable taggable mode. Transform autocomplete into combobox.
     */
    taggable: Boolean,
  },
  data() {
    return {
      search: null,
      items: [],
      seleccion: null,
    };
  },

  methods: {
    customFilter(item) {
      console.log("item-text: " + this.textoColumna);
      console.log("customFilter: " + item[this.textoColumna]);

      return item[this.textoColumna];
    },

    async loadCurrentChoices(value) {
      if (this.reference && value) {
        this.items = await this.fetchCurrentChoices(
          this.multiple ? value : [value]
        );
      }
    },
  },
  watch: {
    seleccion(value) {
     console.log("valuesss: " + value);
   this.$emit("input", value);
  
    },
  
    input: {
      handler(newVal) {
        /**
         * Fetch full object as soon as we get input value
         */

        this.loadCurrentChoices(newVal);
      },
      immediate: true,
    },
    async search(val, old) {

      console.log('val ' + val + ' old ' + old);
      if (!val || val.length < this.minChars || !old || val.length < old.length) {
        return;
      }
      this.items = [...((await this.fetchChoices(val)) || [])];
    },
  },
};
</script>
