<template>
  <base-material-card :icon="resource.icon" :title="title">
    <va-list :filters="filters">
      <va-data-table
        group-by="mes"
        :fields="fields"
        class="elevation-1"
        show-expand
        disableSelect
        disableActions
      >
        <template v-slot:[`field.exportar`]="{ item }">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="green"
            class="ma-2 white--text"
            fab
            dark
            small
            @click.prevent="terminarProceso(item.uuid_export)"
          >
            <v-icon center dark> mdi-file-excel </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`field.total`]="{ item }">
          $ {{ item.total }}
        </template>
        <template v-slot:expanded-item="{ item }">
          <div class="row">
            <div class="col-sm-4">Nombre y Apellido</div>
            <div class="col-sm-4">Legajo</div>
            <div class="col-sm-4">Consumo total</div>
          </div>
          <div class="row" v-for="venta in item.ventas" :key="venta.id">
            <div class="col-sm-4">{{ venta.apellido_nombre }}</div>
            <div class="col-sm-4">{{ venta.legajo }}</div>
            <div class="col-sm-4">$ {{ venta.total }}</div>
          </div>
        </template>
      </va-data-table>
    </va-list>
  </base-material-card>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.all.js";

const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000";

export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        { source: "mes", type: "select" },
        { source: "anio", type: "select" },
      ],
      fields: [
        { source: "mes", sortable: true },
        { source: "anio", sortable: true },
        { source: "total", sortable: true },
        { source: "emitido", type: "Boolean", sortable: true },
        { source: "exportar", sortable: false },
      ],
    };
  },
  methods: {
    terminarProceso(uuid_export) {
      Swal.fire({
        icon: "info",
        title:
          "Advertencia" +
          " al exportar este reporte se eliminaran los reportes" +
          " existentes del mismo periodo y se modificara el estado de las ventas a PAGADO",
        showCancelButton: true,
        confirmButtonText: "Exportar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store
            .dispatch("reportesmutual/getList", {
              include: {
                terminar: true,
                uuid_export: uuid_export,
              },
            })
            .then(async (response) => {
              this.response = response;
              if (response.data == true) {
                window.open(
                  baseURL + "/reportes-mutual/" + uuid_export,
                  "_blank"
                );
                await this.$store.dispatch("reportesmutual/getList");
              } else {
                Swal.fire(
                  "Ops..! Ocurrio un error",
                  "" + response.data.error,
                  "error"
                );
              }
            });
        } else {
          Swal.fire("Cancelado con exito!", "", "info");
        }
      });
    },
  },
  mounted() {},
};
</script>
