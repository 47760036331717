<template>
    <div>
        <va-select-obj-input
            source="producto"
            reference="productos"
            item-text="nombre"
            item-value="id"
            return-object
            v-model="productoSeleccionado"
        ></va-select-obj-input>

        <va-number-input
            source="cantidad"
            v-model="cantidad"
            required
        ></va-number-input>

        <v-btn small color="info" @click="agregarProductos()">Agregar</v-btn>

        <base-material-card :icon="resource.icon" title="Detalle">
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Nombre</th>
                            <th class="text-left">Cantidad</th>
                            <th class="text-left">Precio</th>
                            <th class="text-left">Total</th>
                            <th class="text-left">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in productosenventa" :key="item.name">
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.cantidad }}</td>
                            <td>$ {{ item.precio }}</td>
                            <td>$ {{ item.total }}</td>
                            <td>
                                <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </base-material-card>

        <v-row justify="center" align="center">
            <v-chip class="ma-2" color="primary" label>
                <v-icon left> mdi-currency-usd </v-icon>
                <h1>{{ totalventa }}</h1>
            </v-chip>
        </v-row>
    </div>
</template>

<script>
import Field from "vuetify-admin/src/mixins/field";

export default {
    mixins: [Field],
    data() {
        return {
            precio: 0,
            productoSeleccionado: [],
            cantidad: 1,
            total: 0,
            totalventa: 0,
            productosenventa: [],
        };
    },
    methods: {
        deleteItem: function (itemDelete) {
            this.totalventa -= itemDelete.total;
            this.productosenventa.splice(
                this.productosenventa.indexOf(itemDelete),
                1
            );
        },
        agregarProductos: function () {
            this.total = this.productoSeleccionado.precio * this.cantidad;

            this.productosenventa.push({
                nombre: this.productoSeleccionado.nombre,
                cantidad: this.cantidad,
                precio: this.productoSeleccionado.precio,
                total: this.total,
            }),
                (this.totalventa += this.total);

            console.log(this.productosenventa);
        },
    },
    mounted() {},
};
</script>
