<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters">
            <va-data-table :fields="fields"> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: ["nombreapellido", "dni", "legajo"],
            fields: [
                { source: "mes", type: "select" },
                { source: "anio", type: "select" },
                { source: "legajo", sortable: true },
                { source: "dni", sortable: true },
                { source: "nombreapellido", sortable: true },
            ],
        };
    },
};
</script>
